import { format, parseISO } from "date-fns";

export default function formatString(
  value,
  type = "string",
  decimals = 2,
  currency = "BRL"
) {
  if (type.toLocaleLowerCase() === "currency") {
    return new Intl.NumberFormat("pt-BR", {
      localeMatcher: "best fit",
      style: "currency",
      currency
    })
      .format(value)
      .split("")
      .map(x => {
        if (x.charCodeAt(0) === 160) {
          return " ";
        } else {
          return x;
        }
      })
      .join("");
  }
  if (type.toLocaleLowerCase() === "number") {
    return Intl.NumberFormat("pt-BR", {
      style: "decimal",
      minimumFractionDigits: decimals,
      maximumFractionDigits: decimals
    })
      .format(value)
      .split("")
      .map(x => {
        if (x.charCodeAt(0) === 160) {
          return " ";
        } else {
          return x;
        }
      })
      .join("");
  }
  if (type.toLocaleLowerCase() == "date") {
    console.log("data", value);
    if (value.trim() === "") {
      return "";
    }
    return format(parseISO(value), "dd/MM/yyyy");
  }
  if (type.toLocaleLowerCase() === "string") {
    if (value === "") {
      return "";
    }
    return value?.toUpperCase();
  }
  return value;
}
